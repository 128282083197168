import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

Fancybox.bind("[data-fancybox]", {});

const navLink = document.querySelectorAll(
    ".navigation__dropdown > .navigation__link"
);
const navLinkTrigger = document.querySelectorAll(
    ".navigation__dropdown .navigation__arrow"
);
const navLinkBack = document.querySelectorAll(".navigation__link--back");
const navDropdown = document.querySelector(".navigation__dropdown");
const navOpenMobileSubmenu = document.querySelectorAll('.navigation__link--open-submenu');
const headerNav = document.querySelector(".header__navigation");
const headerWrapper = document.querySelector(".header__wrapper");
const dropdownTrigger = document.querySelector(".actions__toggle");
const headerMenu = document.querySelector(".header__menu--mobile");
const category = document.querySelectorAll(".category");
const faq = document.querySelectorAll(".faq");
const faqMobile = document.querySelector(".select-category-mobile");
const modal = document.querySelector(".modal.modal--search");
const modalOpen = document.querySelectorAll(".open-search");
const modalClose = document.querySelector(".modal--search .close-modal");
const reference = document.querySelectorAll(".reference");
const plusminus = document.querySelectorAll(".plusminus");
const faqContainer = document.querySelector('.faq__container');
const faqCategories = document.querySelector('.faqs__categories');

window.addEventListener("DOMContentLoaded", () => {
    if (navLink)
        navLink.forEach((item) => {
            item.addEventListener("click", () => {
                item.classList.toggle("is-active");
                item.parentElement.classList.toggle("is-active");
                item.parentElement
                    .querySelector(".dropdown__content")
                    .classList.toggle("is-active");
            });
        });

    if (navLinkTrigger)
        navLinkTrigger.forEach((item) => {
            item.addEventListener("click", () => {
                headerWrapper.classList.add("dropdown-active");
                item.parentElement
                    .querySelector(".dropdown__content")
                    .classList.add("dropdown-active");
                headerNav.classList.add("dropdown-active");
            });
        });

    if (navLinkBack)
        navLinkBack.forEach((item) => {
            item.addEventListener("click", () => {
                item.parentElement.classList.remove("dropdown-active");
                headerNav.classList.remove("dropdown-active");
                headerWrapper.classList.remove("dropdown-active");
            });
        });



    if (dropdownTrigger)
        dropdownTrigger.addEventListener("click", () => {
            dropdownTrigger.classList.toggle("dropdown-active");
            headerMenu.classList.toggle("dropdown-active");
            document.body.classList.toggle("no-scroll");
        });

    //Search modal open/close
    if (modalOpen)
        modalOpen.forEach((item) => {
            item.addEventListener("click", () => {
                modal.classList.add("is-active");
            });
        });

    if (modal)
        modalClose.addEventListener("click", () => {
            modal.classList.remove("is-active");
        });

    modal.addEventListener("click", (e) => {
        if (e.target.classList.contains("modal--search")) {
            this.classList.remove("is-active");
        }
    });

    //Reference modal open/close
    if (reference)
        reference.forEach((item) => {
            const modalClose = item.querySelector(".close-modal");

            item.addEventListener("click", (e) => {
                if (e.target.classList.contains("reference") == true) {
                    e.target.querySelector(".modal").classList.add("is-active");
                }
            });

            modalClose.addEventListener("click", (e) => {
                e.target.parentElement.parentElement.parentElement.classList.remove(
                    "is-active"
                );
            });

            item.addEventListener("click", (e) => {
                if (this.querySelector(".modal")) {
                    if (e.target.classList.contains("modal")) {
                        this.querySelector(".modal").classList.remove(
                            "is-active"
                        );
                    }
                }
            });
        });

    if (plusminus)
        plusminus.forEach((item) => {
            item.parentElement.addEventListener("click", () => {
                item.classList.toggle("active");
            });
        });
});

let savedScrollPosition = 0;
window.addEventListener("scroll", () =>
{
  if (!faqContainer) {
    return;
  }

  const y = faqContainer.getBoundingClientRect().top;

  if (y > savedScrollPosition && y < 50) {
    faqCategories.classList.add('is-pushed');
  } else {
    faqCategories.classList.remove('is-pushed');
  }

  savedScrollPosition = y;
});

if (category.length > 0) {
  category.forEach((item) => {
    item.addEventListener("click", () => {
      category.forEach((item) => {
        item.classList.remove("is-active");
      });
      item.classList.add("is-active");
      window.scrollTo(0, 0);

      if (item.classList.contains("is-active")) {
        faq.forEach(function (el) {
          if (el.dataset.id === item.dataset.id) {
            el.style.display = "inline-block";
          } else {
            el.style.display = "none";
          }
        });
      } else {
        faq.forEach(function (el) {
          el.style.display = "block";
        });
      }
    });
  });
}

if (faqMobile) {
  faqMobile.querySelector('select').addEventListener("change", (e) => {
    faq.forEach(function (el) {
      if (el.dataset.id === e.target.value) {
        el.style.display = "inline-block";
      } else {
        el.style.display = "none";
      }
    });
  });
}

if (navOpenMobileSubmenu && window.innerWidth <= 1024) {
  navOpenMobileSubmenu.forEach((item) => {
    item.addEventListener("click", (e) => {
      e.preventDefault()
      item.nextElementSibling.click()
    });
  })
}
